import * as React from "react"
import { Col, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Section from "../../components/ui/base/Section"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { createMarkup } from "../../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import {
  PHOTO_TOURS_URL,
  PHOTOGRAPHERS_URL,
  STORY_URL,
} from "../../util/constants"
import { useTranslation } from "react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Link from "../../components/ui/base/Link"
import TourCard from "../../components/ui/base/TourCard"

const StoryPage = ({ data }) => {
  const { i18n: l } = useTranslation()

  const story = data.strapiStory
  const storyLink = `${STORY_URL[l.language]}/${story.slug}`

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={story.seo[0]?.metaTitle}
        description={story?.seo[0]?.metaDescription}
        canonical={storyLink}
        language={l.language}
        openGraph={{
          type: "website",
          url: storyLink,
          title: story.seo[0]?.metaTitle,
          description: story.seo[0]?.metaDescription,
          images: [
            {
              url: story?.seo[0]?.shareImage.formats.medium.url,
              width: story?.seo[0]?.shareImage.formats.medium.width,
              height: story?.seo[0]?.shareImage.formats.medium.height,
              alt: story?.seo[0]?.metaTitle,
            },
          ],
          site_name: "WeShoot",
        }}
      />
      <Section variant={"dark"}>
        <Row>
          <Col xs={12}>
            <div className={classnames([styles.headerContainer])}>
              <Typography className="animated fadeInDown" variant="heading1">
                {story.name}
              </Typography>
              <Breadcrumbs
                elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Viaggi Fotografici",
                    path: `/${PHOTO_TOURS_URL[l.language]}/`,
                  },
                  {
                    name: "Storie di viaggio",
                    path: `/${PHOTO_TOURS_URL[l.language]}/${
                      STORY_URL[l.language]
                    }/`,
                  },
                  {
                    name: story.name,
                    path: `/${PHOTO_TOURS_URL[l.language]}/${
                      STORY_URL[l.language]
                    }/${story.slug}`,
                  },
                ]}
              />
              <div className={classnames([styles.coachContainer])}>
                <div className={classnames([styles.leftContainer])}>
                  <div className={classnames([styles.profileContainer])}>
                    <GatsbyImage
                      image={
                        story.photographer.profilePicture.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={story.photographer.firstName}
                    />
                  </div>

                  <div className={classnames([styles.details])}>
                    <Typography variant="heading3">
                      <Link
                        to={`/${PHOTOGRAPHERS_URL[l.language]}/${
                          story.photographer.username
                        }`}
                        target="_blank"
                      >
                        {story.photographer.firstName}
                      </Link>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <GatsbyImage
              className={classnames([styles.gatsbyImage])}
              alt={story.name}
              image={story?.photo?.localFile?.childImageSharp.gatsbyImageData}
            />
            <div className={classnames([styles.storyDescription])}>
              <Typography className="animated fadeInDown" variant="heading2">
                La storia dietro a questo scatto
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(story.description)} />
            </div>
          </Col>
        </Row>
        <Row className={classnames([styles.photoTour])}>
          <Col xs={12}>
            <Typography className="animated fadeInDown" variant="heading2">
              Foto scattata durante questo viaggio fotografico
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <TourCard tour={story.tour} />
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default StoryPage

export const query = graphql`
  query StoryQuery($slug: String!) {
    strapiStory(slug: { eq: $slug }) {
      id
      name
      slug
      description
      photo {
        localFile {
          ...ImageFragment
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          formats {
            medium {
              url
              width
              height
            }
          }
        }
      }
      photographer {
        id
        firstName
        username
        bio
        instagram
        profilePicture {
          localFile {
            ...ImageFragment
          }
        }
      }
      tour {
        id
        image {
          url
          localFile {
            ...ImageFragment
          }
        }
        sessions {
          end
          id
          sessionId
          start
          status
          maxPax
          balance
          price
          deposit
          currency
          users {
            id
            firstName
            instagram
            profilePicture {
              localFile {
                ...ImageFragment
              }
            }
          }
        }
        price
        slug
        excerpt
        title
        places {
          slug
        }
        states {
          slug
        }
      }
    }
  }
`
