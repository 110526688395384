import classnames from "classnames"
import React from "react"
import Link from "/src/components/ui/base/Link"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import Button from "/src/components/ui/base/Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { getTourDays, getTourLink, getTourPrice } from "../../../../util/tour"

const TourCard = ({ tour }) => {
  const { t, i18n: l } = useTranslation()
  const tourLink = getTourLink(tour, l)

  const { price: tourPrice, currency: tourCurrency } = getTourPrice(tour)

  if (tour.sessions[0]) {
    return (
      <Link to={tourLink}>
        <div className={classnames([styles.container])}>
          <div className={classnames([styles.img_container])}>
            <Link to={tourLink}>
              <GatsbyImage
                alt={tour.title}
                image={tour?.image?.localFile?.childImageSharp.gatsbyImageData}
              />
            </Link>
          </div>
          <div className={classnames([styles.card_body])}>
            <div className={classnames([styles.card_content])}>
              <Typography
                variant="heading3"
                className={classnames([styles.card_title])}
              >
                <Link to={tourLink}>{tour.title}</Link>
              </Typography>
              <div
                className={"d-flex align-items-center justify-content-between"}
              >
                <div className={classnames([styles.coaches])}>
                  {tour.sessions[0].users?.map((u, index) => {
                    return (
                      <div
                        key={`users-${index}`}
                        className={classnames([styles.profile])}
                      >
                        <GatsbyImage
                          image={
                            u.profilePicture.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={u.firstName}
                        />
                      </div>
                    )
                  })}
                  <div className={classnames([styles.coaches_names])}>
                    {tour.sessions[0].users?.map((u, index) => {
                      return (
                        <span key={`users-name-${index}`}>
                          {index === tour.sessions[0].users.length - 1
                            ? u.firstName + " "
                            : u.firstName + ", "}
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={classnames([styles.card_footer])}>
              <div
                className={"d-flex align-items-center justify-content-between"}
              >
                <div className={"d-flex flex-column  justify-content-between"}>
                  <div className={classnames([styles.card_day])}>
                    <div className={classnames([styles.card_day_text])}>
                      {getTourDays(
                        tour.sessions[0].start,
                        tour.sessions[0].end
                      )}{" "}
                      {t("ui.components.tripCard.days")}
                    </div>
                  </div>

                  <div className={classnames([styles.card_price])}>
                    <div>
                      <span className={classnames([styles.card_price_value])}>
                        {tourPrice > 0 ? (
                          <>
                            {tourCurrency}
                            {tourPrice}
                          </>
                        ) : (
                          <>Scopri</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <Button variant={"link"} to={tourLink}>
                  {t("ui.components.tripCard.showTrip")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  } else {
    return <></>
  }
}

export default TourCard
