// extracted by mini-css-extract-plugin
export var container = "index-module--container--6vKTN";
export var coaches = "index-module--coaches--hx3HH";
export var profile = "index-module--profile--Nzby1";
export var coaches_names = "index-module--coaches_names--SRxQ3";
export var img_container = "index-module--img_container--GXigC";
export var card_body = "index-module--card_body--RF365";
export var card_content = "index-module--card_content--RXUbj";
export var card_title = "index-module--card_title--cxWHM";
export var card_footer = "index-module--card_footer--Epn5F";
export var card_day = "index-module--card_day--vn7HW";
export var card_price = "index-module--card_price--MQuU9";
export var card_price_value = "index-module--card_price_value--3PN2y";