import React from "react"
import PropTypes from "/src/util/PropTypes"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Typography from "../typography"
import capitalize from "../../../../util/capitalize"
import { Link } from "gatsby"

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["white", "grey", "dark"]),
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  linkAll: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string,
}

const defaultProps = {
  children: null,
  className: null,
  fullWidth: false,
  variant: "white",
}

const Section = ({
  children,
  className,
  title,
  subtitle,
  variant,
  linkAll,
  id,
  fullWidth,
}) => {
  const sectionClassName = classnames(
    styles.section,
    styles[`variant${capitalize(variant)}`],
    className
  )

  return (
    <div className={sectionClassName} id={id}>
      <div
        className={classnames([
          fullWidth ? styles[`fullWidth`] : styles[`container`],
        ])}
      >
        {(title || subtitle) && (
          <div className={classnames([styles.titleContainer])}>
            <div className={classnames([styles.mainTitle])}>
              {title && <Typography variant="heading2">{title}</Typography>}
              {subtitle && (
                <Typography variant="heading3">{subtitle}</Typography>
              )}
            </div>
            {linkAll && <Link to={linkAll}>Vedi tutti →</Link>}
          </div>
        )}
        <div className={className}>{children}</div>
      </div>
    </div>
  )
}

Section.propTypes = propTypes
Section.defaultProps = defaultProps

export default Section
