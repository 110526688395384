import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, useStaticQuery } from "gatsby"
import * as styles from "./index.module.scss"
import classnames from "classnames"

const Breadcrumbs = (props) => {
  const { site }= useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <div>
      <ul className={classnames([styles.breadCrumbUl])}>
        {props.elements.map((element, i) => {
          return (
            <li className={classnames([styles.breadCrumbLi])} key={i}>
              {i > 0 && <span>/</span>}
              <Link to={element.path}>{element.name}</Link>
            </li>
          );
        })}
      </ul>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: props.elements.map((element, i) => {
              return {
                "@type": "ListItem",
                position: i + 1,
                name: element.name,
                item: site.siteMetadata.siteUrl + element.path,
              };
            }),
          })}
        </script>
      </Helmet>
    </div>
  );
};

export default Breadcrumbs;
