// extracted by mini-css-extract-plugin
export var headerContainer = "index-module--headerContainer--1K48+";
export var storyDescription = "index-module--storyDescription--5heey";
export var photoTour = "index-module--photoTour--PEG-g";
export var coachContainer = "index-module--coachContainer--wRumA";
export var leftContainer = "index-module--leftContainer--popUf";
export var profileContainer = "index-module--profileContainer--OX9R5";
export var details = "index-module--details--CF3ka";
export var weshooterBadge = "index-module--weshooterBadge--HYFHd";
export var icon = "index-module--icon---i8JI";
export var bio = "index-module--bio--NWRke";